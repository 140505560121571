import * as React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import redirect from "../../../lib/redirect";
import { useRouter } from "next/router";
import { MeComponent } from "../../../generated/apolloComponents";

type Props = {
  title?: string;
  textAlign?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  quoteContainer: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(0deg, rgba(63, 81, 181, 0.4), rgba(63, 81, 181, 0.5)),url('/background/login-background.jpg')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "50px 5%",
    [theme.breakpoints.down("lg")]: {
      flexGrow: 1,
    },
  },
  logo: {
    width: "75px",
    height: "75px",
    marginBottom: "10px",
    objectFit: "cover",
    [theme.breakpoints.down("lg")]: {
      width: "50px",
      height: "50px",
    },
  },
  quoteBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px 5%",
    // [theme.breakpoints.down('sm')]: {
    // 	padding: '20px 5%',
    // }
  },
  quoteText: {
    color: theme.typography.h2.color,
    textAlign: "center",
  },
  contentContainer: {
    display: "flex",
    backgroundColor: "#fff",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "-5px 0 10px rgba(63,63,68,.2)",
    [theme.breakpoints.down("lg")]: {
      flexGrow: 1,
    },
  },
}));

const LoginChildrenLayout = ({ children }: any) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item md={5} className={classes.quoteContainer}>
        <Paper elevation={3} className={classes.quoteBox}>
          <img
            src={process.env.NEXT_PUBLIC_WEBSITE_LOGO_PATH}
            className={classes.logo}
          />
          <Typography variant="h2" className={classes.quoteText}>
            {process.env.NEXT_PUBLIC_WEBSITE_NAME}
          </Typography>
        </Paper>
      </Grid>
      <Grid item sm={12} md={7} className={classes.contentContainer}>
        {children}
      </Grid>
    </Grid>
  );
};

const LoginLayout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <div>
      {/*If login page from auto redirect to home page if user had logged in */}
      {router.asPath.includes("login") ? (
        <MeComponent fetchPolicy={"network-only"}>
          {/* Need to use network-only for this case, else after login submit, will reload this part */}
          {({ loading, data }) => {
            if (loading) return <CircularProgress />;

            if (data && data.me) {
              redirect("/");
            } else {
              return (
                <LoginChildrenLayout classes={classes} children={children} />
              );
            }

            return null;
          }}
        </MeComponent>
      ) : (
        <LoginChildrenLayout classes={classes} children={children} />
      )}
    </div>
  );
};

export default LoginLayout;

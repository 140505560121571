import constants from "../constants";

export const isValidEmail = (email: string) => {
  constants.REGEX_VALID_EMAIL.lastIndex = 0;
  return constants.REGEX_VALID_EMAIL.test(email);
};

export const isValidUserName = (userName: string) => {
  constants.REGEX_VALID_USERNAME.lastIndex = 0;
  return constants.REGEX_VALID_USERNAME.test(userName);
};

export const isValidPassword = (password: string) => {
  constants.REGEX_VALID_PASSWORD.lastIndex = 0;
  return constants.REGEX_VALID_PASSWORD.test(password);
};

// constants.REGEX_VALID_PASSWORD.lastIndex = 0; <--- To reset the global cache. There is bug when multiple times calling the same regex will return true then false then true and repeat

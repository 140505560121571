// import Layout from "../src/components/Layout/MainLayout";
import LoginForm from "../src/components/Form/LoginForm";
// import { MyContext } from "../interfaces/MyContext";
// import redirect from "../lib/redirect";
// import { meQuery } from "../graphql/queries/user/me";
import generateMetaTitle from "../lib/generateMetaTitle";
import LoginLayout from "../src/components/Layout/LoginLayout";

const LogIn = () => {
  return <LoginForm />;
};

// LogIn.getInitialProps = async function ({
//   res,
//   pathname,
//   apolloClient,
// }: MyContext) {
//   // Server side checking for logged in user (not implemented on client side)
//   // -------------
//   // const cookieRegexp = /;?\s?(qid=.*);?/g;

//   // if (req && req.headers && req.headers.cookie && cookieRegexp.test(req.headers.cookie)) {

//   // 	// console.log(cookieRegexp.test(req.headers.cookie));
//   // 	redirect(res, "/");
//   // }

//   if (res) {
//     try {
//       const response = await apolloClient.query({
//         query: meQuery,
//       });

//       console.log(response);

//       if (response.data && response.data.me) {
//         redirect("/", res);
//         return {};
//       }
//     } catch (err) {}
//   }

//   return {
//     pathname: pathname,
//   };
// };

LogIn.Layout = LoginLayout;
LogIn.metaProps = { title: generateMetaTitle("Login") };

export default LogIn;

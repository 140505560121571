import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { Form, Field } from "react-final-form";
import Router from "next/router";
import { Theme } from "@mui/material/styles";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import makeStyles from "@mui/styles/makeStyles";
import {
  LoginWithEmailComponent,
  MeQuery,
  User,
} from "../../../generated/apolloComponents";
import { meQuery } from "../../../graphql/queries/user/me";
import MyLink from "../MyLink";
import { isValidEmail } from "../../../lib/regexChecking";

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    padding: "125px 100px",
    flexBasis: 700,
    [theme.breakpoints.down("lg")]: {
      paddingTop: 50,
      paddingBottom: 50,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  loginInButton: {
    width: "100%",
    margin: theme.spacing(2, 0),
  },
  forgetPassword: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      paddingTop: "0 !important",
    },
  },
}));

const LoginForm = () => {
  const classes = useStyles();

  const required = (value: any) => {
    if (!value) {
      return "Required";
    } else {
      return undefined;
    }
  };

  const emailValidate = (value: any) => {
    if (!value) {
      return "Required";
    }

    if (!isValidEmail(value)) {
      return "Please Enter A Valid Email";
    } else {
      return undefined;
    }
  };

  return (
    <LoginWithEmailComponent>
      {(loginWithEmail) => {
        return (
          <Form
            onSubmit={async ({ email, password }) => {
              try {
                await loginWithEmail({
                  variables: { email, password },
                  update: (cache, { data }) => {
                    if (!data || !data.loginWithEmail) {
                      return;
                    }

                    cache.writeQuery<MeQuery>({
                      query: meQuery,
                      data: {
                        me: data.loginWithEmail as User,
                      },
                    });
                  },
                });

                // if (response && response.data && response.data.login) {
                //     // setCreatedUrl(response.data.login);
                //     // setCopied(false);

                //     console.log(response.data.login);
                // }

                Router.push(
                  Router.query.from
                    ? decodeURIComponent(
                        Array.isArray(Router.query.from)
                          ? Router.query.from[0]
                          : Router.query.from
                      )
                    : "/"
                );
              } catch (err: any) {
                return err.graphQLErrors[0]
                  ? err.graphQLErrors[0].message
                  : err.message;
              }
            }}
            initialValues={{ email: "", password: "" }}
            keepDirtyOnReinitialize={true}
            render={({
              handleSubmit,
              submitErrors,
              pristine,
              submitting,
              hasSubmitErrors,
              hasValidationErrors,
              dirtySinceLastSubmit,
            }) => (
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <Typography variant="h1">Log In</Typography>
                {submitErrors && (
                  <Typography variant="body1" color="error">
                    {submitErrors as unknown as string}
                  </Typography>
                )}

                <div>
                  <Field name="email" validate={emailValidate}>
                    {({ input, meta }: InputProps & any) => (
                      <div>
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          inputProps={{ ...input }}
                          className={classes.textField}
                          error={meta.error && meta.modified}
                          helperText={
                            meta.error && meta.modified ? meta.error : ""
                          }
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="password" validate={required}>
                    {({ input, meta }: InputProps & any) => (
                      <div>
                        <TextField
                          id="password"
                          label="Password"
                          variant="outlined"
                          className={classes.textField}
                          inputProps={{ ...input, type: "password" }}
                          error={meta.error && meta.modified}
                          helperText={
                            meta.error && meta.modified ? meta.error : ""
                          }
                        />
                      </div>
                    )}
                  </Field>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  className={classes.loginInButton}
                  disabled={
                    pristine ||
                    submitting ||
                    hasValidationErrors ||
                    (hasSubmitErrors && !dirtySinceLastSubmit)
                  }
                >
                  Log in Now
                </Button>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography color="textSecondary" variant="body1">
                      Don't have an account?{" "}
                      <MyLink href="/register" variant={"h6"}>
                        Register
                      </MyLink>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.forgetPassword}>
                    <Typography color="textSecondary" variant="body1">
                      Forget Password?{" "}
                      <MyLink href="/forget-password" variant={"h6"}>
                        Reset Now
                      </MyLink>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        );
      }}
    </LoginWithEmailComponent>
  );
};

export default LoginForm;
